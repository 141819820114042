<template>
  <BasicModal
    :action="updatePPA"
    :close-on-action="false"
    :action-title="actionTitle"
    :disable-cancel-button="isLoading"
    :show-action-button="!form.isSubmitted || isLoading"
    :show-cancel-button="!form.isSubmitted || isLoading"
    :disable-action-button="isLoading || (newValue && !form.fields.overagesAllowed.value)"
  >
    <template v-if="!form.isSubmitted || isLoading">

      <LoadingOverlay v-if="isLoading" />

      <template v-if="newValue">
        <h4>Turn on Pay per Action?</h4>
        <p>
          $0.02 will automatically be charged for each Action that exceeds your
          monthly {{ userPlan.name }} Plan limit
          ({{ formatNumber(userPlan.actionEventLimit) }} Actions).
        </p>
        <p>
          For months you have overages, you’ll be charged and get a receipt
          after your usage cycle ends. This will be
          charged separately from your {{ payPeriod.toLowerCase() }}
          subscription fee.
        </p>
        <FormCheckbox :formName="formName" fieldName="overagesAllowed" />
      </template>

      <template v-else>
        <h4>Deactivate Pay per Action?</h4>
        <p>
          You currently have
          <strong>{{ actionsRemaining }} Actions</strong>
          remaining in your usage cycle.
        </p>
        <p v-if="actionsRemaining === 0">
          This means your Patches will pause immediately, and you may miss
          important alerts until your usage cycle resets in
          <strong>{{ daysUntilUsageCycleReset }} days.</strong>
        </p>
        <p v-else>
          Your Patches will pause immediately if you use all remaining Actions
          before <strong>{{ usageCycleEndsAt }}</strong> (when your usage cycle
          restarts).
        </p>
        <p>
          <strong class="text-danger-700">
            Deactivating Pay per Action will take effect immediately.
          </strong>
        </p>
      </template>

    </template>
    <template v-else>

      <template v-if="newValue">
        <div class="success-container">
          <img class="mb-4" src="@/assets/images/plans/satellite.png" />
          <h4>Pay per Action activated</h4>
          <p>
            Congrats! Worrying about plan limits is a thing of the past.
          </p>
          <p>
            If you hit your Actions limit, your Patches will keep running.
          </p>
          <div class="mt-4 flex gap-4">
            <button class="primary small" @click="close">Back to Dispatch</button>
          </div>
        </div>
      </template>

      <template v-else>
        <!--
          @NOTE: there is no success screen for deactivating PPA, instead we
          close the modal and show a toast
        -->
      </template>

    </template>

  </BasicModal>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'

  import BasicModal from '@/components/modals/_BasicModal.vue'
  import FormCheckbox from '@/components/forms/FormCheckbox.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    inject: [
      '$mixpanel',
      '$helpscout',
    ],
    components: {
      BasicModal,
      FormCheckbox,
      LoadingOverlay,
    },
    props: {
      newValue: {
        type: Boolean,
        required: true,
      },
    },
    setup() {

      // data
      const formName = 'payPerActionConfirmationForm'

      // composables
      const { formatNumber } = useFilters()
      const { form } = useForm({ formName })

      return {
        form,
        formName,
        formatNumber,
      }

    },
    computed: {
      ...mapState('user', ['user', 'userPlan']),
      ...mapGetters('user', [
        'payPeriod',
        'isPPAEnabled',
        'isOnFreePlan',
        'actionsRemaining',
        'usageCycleEndsAt',
        'isNearActionLimit',
        'isOverActionLimit',
        'daysUntilUsageCycleReset',
      ]),
      actionTitle() {
        return this.newValue
          ? 'Activate Pay per Action'
          : 'Deactivate'
      },
    },
    data() {
      return {
        isLoading: false,
      }
    },
    created() {
      this.$mixpanel.onReady((mixpanel) => {
        mixpanel.track('PPA Confirmation Modal Opened', {
          newValue: this.newValue,
          oldValue: !this.newValue,
          isOnFreePlan: this.isOnFreePlan,
          isPPAEnabled: this.isPPAEnabled,
          usageCycleEndsAt: this.usageCycleEndsAt,
          actionsRemaining: this.actionsRemaining,
          isNearActionLimit: this.isNearActionLimit,
          isOverActionLimit: this.isOverActionLimit,
        })
      })
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      updatePPA() {

        if (this.isOnFreePlan) return null

        // @NOTE: we don't just use the form's isLoading value since we want the
        // loading overlay to still show when we're doing the "addon update
        // check" after form submission
        this.isLoading = true

        return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then(() => {
            return this.$store.dispatch('user/REFRESH_USER_PLAN', this.formName)
              .then(() => {

                if (this.newValue !== this.isPPAEnabled) {
                  throw new Error('Unexpected result from server after updating Pay per Action.')
                }

                this.$helpscout.onReady((helpscout) => {
                  helpscout('identify', {
                    email: this.user.email,
                    'is-ppa-enabled': this.isPPAEnabled,
                  })
                })

                this.$mixpanel.onReady((mixpanel) => {

                  mixpanel.register({
                    isPPAEnabled: this.isPPAEnabled,
                  })

                  mixpanel.track(`PPA Status Updated (${this.isPPAEnabled ? 'Activated' : 'Deactivated'})`, {
                    newValue: this.newValue,
                    oldValue: !this.newValue,
                    isPPAEnabled: this.isPPAEnabled,
                    isNearActionLimit: this.isNearActionLimit,
                    isOverActionLimit: this.isOverActionLimit,
                  })

                })

              })
          })
          .then(() => {

            this.$store.commit('forms/SET_FIELD_VALUE', {
              fieldName: 'isPayPerActionEnabled',
              formName: 'userPlanForm',
              newValue: this.isPPAEnabled,
            })

            if (!this.isPPAEnabled) {
              this.close()
              this.$store.dispatch('toast/CREATE_TOAST', {
                text: 'Pay per Action deactivated.',
                type: 'success',
              })
            }

          })
          .catch(() => {
            this.close()
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not update Pay per Action. Please try again later.',
              type: 'error',
            })
          })
          .finally(() => {
            this.isLoading = false
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  p
    @apply mb-4

  .success-container
    @apply flex
    @apply flex-col
    @apply items-center

</style>
